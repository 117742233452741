<template>
  <div>
    <a-form
      :layout="formLayout"
      class="adherent_form"
      @submit="handleSubmit"
      :form="form"
    >
      <a-row>
        <a-col :xs="24" :md="12">
          <a-form-item>
            <p class="section_text">
              <a-icon type="user" />
              Etat civil
            </p>
          </a-form-item>
          <div class="clearfix" v-show="showPictureUploader">
            <a-upload
              v-decorator="['upload']"
              listType="picture-card"
              :fileList="photoList"
              :remove="handleRemove"
              :beforeUpload="beforeUpload"
              @preview="handlePreview"
            >
              <div v-if="photoList && photoList.length === 0">
                <a-icon type="plus" />
                <div class="ant-upload-text">Sélectionner</div>
              </div>
            </a-upload>

            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img
                alt="example"
                style="width: 100%;margin-right: 10px;height: auto;"
                :src="previewImage"
              />
            </a-modal>
          </div>

          <a-form-item
            v-bind="{
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 5 },
              },
            }"
            v-show="showPictureUploader"
          >
            <a-button
              type="primary"
              :disabled="photoList.length === 0"
              :loading="uploading"
              @click="handleUpload"
              v-decorator="[
                'photo',
                {
                  rules: [{ validator: this.validateProfilePicture }],
                },
              ]"
              >{{ uploading ? "Importation" : "Joindre" }}</a-button
            >
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              N° d'inscription
              <a-tooltip title="N° d'inscription  ?"></a-tooltip>
            </span>
            <a-input
              :disabled="!isAdmin"
              v-decorator="[
                'matricule',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Le N° d\'inscription est obligatoire!',
                      whitespace: true,
                    },
                    { validator: this.validateMatricule },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              Date dépôt dossier
              <a-tooltip title="Date dépôt dossier provisoire"></a-tooltip>
            </span>
            <a-date-picker
              :format="dateFormat"
              :disabledDate="disabledFutureDates"
              style="width:100%"
              v-decorator="['dt_depot_dossier']"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" v-if="isAdmin">
            <span slot="label">
              Date commission
              <a-tooltip title="Date commission provisoire"></a-tooltip>
            </span>
            <a-date-picker
              :format="dateFormat"
              :disabledDate="disabledFutureDates"
              style="width:100%"
              v-decorator="['dt_comission']"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              Nom (Fr)
              <a-tooltip title="Nom adhérente ?"></a-tooltip>
            </span>
            <a-input
              v-decorator="[
                'nom_fr',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Le nom est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /^[ÇA-Za-z ]{3,}$/,
                      message: 'Le format du nom n\'est pas valide ! ',
                    },
                    { transform: nomToUpperCase },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              Nom (Ar)
              <a-tooltip title="Nom arabe d'adhérente ?"></a-tooltip>
            </span>
            <a-input
              v-decorator="[
                'nom_ar',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Le nom arabe est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /[\u0600-\u06FF]/,
                      message: 'Le format de nom n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              Prénom (Fr)
              <a-tooltip title="Prénom adhérente ?"></a-tooltip>
            </span>
            <a-input
              v-decorator="[
                'prenom_fr',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Le prenom est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /^[ÇA-Za-z ]{3,}$/,
                      message: 'Le format du prénom n\'est pas valide !',
                    },
                    { transform: prenomToUpperCase },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              Prénom (Ar)
              <a-tooltip title="Prénom arabe d'adhérente ?"></a-tooltip>
            </span>
            <a-input
              lang="ar"
              v-decorator="[
                'prenom_ar',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Le prenom arabe est obligatoire !',
                      whitespace: true,
                    },
                    {
                      pattern: /[\u0600-\u06FF]/,
                      message: 'Le format de Prénom n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Sexe">
            <a-radio-group
              @change="handleSexeChange"
              v-decorator="[
                'sexe',
                {
                  rules: [{ required: true, message: 'Le sexe est obligatoire !' }],
                },
              ]"
            >
              <a-radio :value="0">Homme</a-radio>
              <a-radio :value="1">Femme</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Situation familial">
            <a-select
              @change="handleSituationFamilialChange"
              v-decorator="[
                'situation_familial_id',
                {
                  rules: [
                    { required: true, message: 'il faut choisir une option!' },
                    { validator: checkEmptyValue },
                  ],
                },
              ]"
              placeholder="Choisir une situation"
            >
              <a-select-option :value="0">Choisir une situation</a-select-option>
              <a-select-option :value="1">Célibataire</a-select-option>
              <a-select-option :value="2">Marié(e)</a-select-option>
              <a-select-option :value="3">Divorcé(e)</a-select-option>
              <a-select-option :value="4">Veuf(ve)</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" v-show="showNomMarital">
            <span slot="label">
              Nom marital (fr)
              <a-tooltip title="Nom marital adhérente ?"></a-tooltip>
            </span>
            <a-input
              v-decorator="[
                'nom_conj',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Le nom conjoint est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /^[ÇA-Za-z ]{3,}$/,
                      message: 'Le format de Prénom n\'est pas valide ',
                    },
                    { transform: nomMaritalToUpperCase },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" v-show="showNomMarital">
            <span slot="label">
              Nom marital (ar)
              <a-tooltip title="Nom marital adhérente ?"></a-tooltip>
            </span>
            <a-input
              v-decorator="[
                'nom_conj_ar',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Le nom arabe est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /[\u0600-\u06FF]/,
                      message: 'Le format de nom n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="Date naissance">
            <a-date-picker
              style="width: 100%;"
              :format="dateFormat"
              :disabledDate="disableRecentDates"
              v-decorator="[
                'dtn',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'La date de naissance est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Présumé(e) :" v-bind="formItemLayout">
            <a-checkbox
              :defaultChecked="get(this, 'adherant.is_presumer', false)"
              v-decorator="['is_presumer']"
            >
            </a-checkbox>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Lieu naissance">
            <a-input
              v-decorator="[
                'lieu_naiss_fr',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Lieu Naiss est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /.{3}/,
                      message: 'Il faut au moins 3 caractères !',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Lieu naissance (Ar)">
            <a-input
              v-decorator="[
                'lieu_naiss_ar',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Lieu Naiss en arabe est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /.{3}/,
                      message: 'Il faut au moins 3 caractères !',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :md="12">
          <a-form-item>
            <p class="section_text">
              <a-icon type="profile" />
              Profil
            </p>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="E-mail">
            <a-input
              :disabled="!isAdmin"
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      type: 'email',
                      message: 'E-mail! n\'est pas valide ',
                    },
                    {
                      required: !isAdmin,
                      message: 'E-mail est obligatoire!',
                    },
                    {
                      validator: this.validateEmail,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            v-if="isAdmin && adherant"
            label="Mot de passe"
          >
            <a-input
              type="password"
              :disabled="true"
              v-decorator="['pwd', { initialValue: '0000000' }]"
            />
            <a @click="showlPwdUpdateModal">Modifier mot de passe</a>
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="Mot de passe"
            v-if="isAdmin && !adherant"
          >
            <a-input
              placeholder="Mot de passe"
              type="password"
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      required: !isAdmin,
                      message: 'Mot de passe est obligatoire!',
                    },
                    {
                      pattern: /(?=.*[a-zA-Z0-9].*).{8,20}/,
                      message:
                        'Le mot de passe doit contenir au moins 8 caractères !',
                    },
                    { validator: this.validateToNextPassword },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25);"
              />
            </a-input>
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="Confirmation"
            v-if="isAdmin && !adherant"
          >
            <a-input
              type="password"
              @blur="handleConfirmBlur"
              placeholder="Confirmer mot de passe"
              v-decorator="[
                'password_confirmation',
                {
                  rules: [
                    {
                      required: !isAdmin,
                      message: 'Confirmation Mot de passe est obligatoire!',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25);"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <p class="section_text">
              <a-icon type="home" />
              Adresse
            </p>
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              Adresse domicile&nbsp;
              <a-tooltip title="Ou habituez vous ?"></a-tooltip>
            </span>
            <a-textarea
              v-decorator="[
                'adresse_personnel_fr',
                {
                  rules: [
                    {
                      required: !isAdmin,
                      message: 'Adresse est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /.{3}/,
                      message: 'Il faut au moins 3 caractères !',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              Adresse domicile (Ar)
              <a-tooltip title="Ou habituez vous ?"></a-tooltip>
            </span>
            <a-textarea
              v-decorator="[
                'adresse_personnel_ar',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Adresse est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /[\u0600-\u06FF]/,
                      message: 'Le format  l\'adresse n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Wilaya domicile">
            <a-select
              :filter-option="filterWilayaDomicile"
              option-filter-prop="children"
              show-search
              v-decorator="[
                'wilaya_personnel_id',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Wilaya domicile est obligatoire!',
                    },
                  ],
                },
              ]"
              placeholder="Choisir  une wilaya"
            >
              <a-select-option :key="0">Choisir une wilaya</a-select-option>
              <a-select-option
                v-for="wilaya in wilayas"
                :key="wilaya.id"
                :value="wilaya.id"
                >{{ wilaya.nom_fr }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <p class="section_text">
              <a-icon type="phone" />
              Téléphone
            </p>
          </a-form-item>

          <a-form-item label="Tél. mobile" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'tel_personnel_mobile',
                {
                  rules: [
                    {
                      required: !isAdmin,
                      message: 'Le numéro de tél est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/,
                      message: 'Le format de Tél n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Tél. domicile" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'tel_personnel_fixe',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Le numéro de Tél. est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/,
                      message: 'Le format de Tél. n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item label="Tél. urgence" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'tel_professionel_urgence',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Le numéro de tél. est obligatoire!',
                      whitespace: true,
                    },
                    {
                      pattern: /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/,
                      message: 'Le format de Tél. n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <ResetPasswordForm
      ref="resetPassword"
      :visible="isResetPwdFormVsible"
      :processing="updatingPassword"
      @cancel="closePwdUpdateModal"
      @create="handlePasswordUpdate"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Axios from "axios";
import _debounce from "lodash-es/debounce";
import moment from "moment";
import constants from "@/const/const";
import ResetPasswordForm from "@/components/common/dossier_adherant/ResetPasswordForm";
// import AInputPassword from "ant-design-vue/es/input/Password";
import auth from "@/services/auth";

// const ADHERENTS_URL = `/admin/adherents`;
const CHECK_EMAIL_URL = `user/check-email`;
// const CHECK_MATRICULE_URL = `user/check-matricule`;
const MAX_AGE = 90;
const MIN_AGE = 19;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 16,
//       offset: 8,
//     },
//   },
// };
export default {
  name: "PersonalForm",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  components: { ResetPasswordForm },
  mounted() {
    if (this.adherant) {
      if (this.adherant.dtn) {
        this.adherant.dtn = moment(this.adherant.dtn);
        this.adherant.dt_depot_dossier = this.momentDate(
          this.get(this, "adherant.dt_depot_dossier")
        );
        this.adherant.dt_comission = this.momentDate(
          this.get(this, "adherant.dt_comission")
        );
      }
      this.form.setFieldsValue(this.adherant);
      this.sexe = this.adherant.sexe;
      this.situation_familial = this.adherant.situation_familial_id;
    }
    this.url = this.base_url;
  },
  data() {
    this.validateEmail = _debounce(this.validateEmail, 2500);
    this.validateMatricule = _debounce(this.validateMatricule, 1500);
    return {
      confirmDirty: false,
      sexe: 0,
      situation_familial: 0,
      updatingPassword: false,
      isResetPwdFormVsible: false,
      formItemLayout,
      previewVisible: false,
      uploading: false,
      url: null,
    };
  },
  props: [
    "adherant",
    "wilayas",
    "isAdmin",
    "photoList",
    "profilePicUploadUrl",
    "auto",
  ],
  computed: {
    ...mapState({
      base_url: (state) => state.auth.base_url,
    }),
    showNomMarital() {
      return (
        this.isFemme(this.sexe) &&
        (this.isMarie(this.situation_familial) ||
          this.isVeuf(this.situation_familial))
      );
    },
    showPictureUploader() {
      return !!this.adherant;
    },
  },
  methods: {
    filterWilayaDomicile(input, option) {
      return this.filterInput(input, option);
    },
    handleSexeChange(event) {
      this.sexe = event.target.value;
    },
    handleSituationFamilialChange(value) {
      this.situation_familial = value;
    },
    handlePresumerChange(event) {
      this.adherant.is_presumer = event.target.checked;
    },
    previewImage() {
      return this.photoList && this.photoList[0] ? this.photoList[0].url : "";
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePasswordUpdate() {
      this.updatingPassword = true;
      const form = this.$refs.resetPassword.form;
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.updatePassword({
            password: values["password"],
            password_confirmation: values["password_confirmation"],
            id: this.adherant.id,
          })
            .then(() => {
              this.updatingPassword = false;
              this.closePwdUpdateModal();
            })
            .catch(() => (this.updatingPassword = false));
        }
      });
    },
    closePwdUpdateModal() {
      this.isResetPwdFormVsible = false;
    },
    showlPwdUpdateModal() {
      this.isResetPwdFormVsible = true;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    handleChange(info) {
      if (info.file.status === "done") {
        this.$_showSuccessMessage(
          `${info.file.name} à été importé avec succès`
        );
        this.$emit("update_profile_pic");
      }
      if (info.file.status === "removed") {
        this.$emit("clear_profile_pic");
      }
    },
    handleRemove() {
      this.$emit("clear_profile_pic");
    },
    handleUpload() {
      if (!this.adherant) {
        return;
      }
      const { photoList } = this;
      const _that = this;
      const formData = new FormData();
      formData.append("file", photoList[0]);
      formData.append("user_id", this.adherant.id);
      this.uploading = true;

      let axios = Axios.create({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.getAuthorization()}`,
        },
      });
      axios
        .post(this.profilePicUploadUrl, formData, { processData: false })
        .then(() => {
          _that.form.setFields({
            photo: {
              errors: [],
            },
          });
          this.$_showSuccessMessage(`${constants.UPLOAD_SUCCESS}`);
          this.$emit("update_profile_pic");
          this.uploading = false;
        })
        .catch((error) => {
          this.$_throwAnError(`${constants.UPLOAD_FAIL}`);
          this.uploading = false;
        });
    },
    getAuthorization() {
      return auth.getAuthorization();
    },
    beforeUpload(file) {
      const isImage = file.type === "image/jpeg" || file.type === "image/png";
      if (!isImage) {
        this.$_throwAnError("Vous pouvez uniquement Joindre des  images!");
      }
      this.photoList = [...this.photoList, file];
      return false;
    },
    handleSubmit(e) {},
    nomToUpperCase(value) {
      if (value) {
        this.form.setFieldsValue({
          nom_fr: value.toUpperCase(),
        });
      }
    },
    prenomToUpperCase(value) {
      if (value) {
        this.form.setFieldsValue({
          prenom_fr: value.toUpperCase(),
        });
      }
    },
    nomMaritalToUpperCase(value) {
      if (value) {
        this.form.setFieldsValue({
          nom_conj: value.toUpperCase(),
        });
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    checkEmptyValue(rule, value, callback) {
      const defaultValue = 0;
      let message = "Il faut choisir une option";
      this.checkDefaultValue(defaultValue, callback, parseInt(value), message);
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(
          "Les deux mots de passe ne sont pas identiques !"
        );
      }
      callback();
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["password_confirmation"], { force: true });
      }
      callback();
    },
    validateEmail(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }
      let params = {
        email: value,
      };
      if (this.adherant && this.adherant.id) {
        params.user_id = this.adherant.id;
      }
      let axios = Axios.create({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.auto}`,
        },
      });
      let _that = this;
      axios
        .post(`${this.url}/${CHECK_EMAIL_URL}`, params)
        .then(() => {
          callback();
        })
        .catch((re) => {
          if (re.response && re.response.status === this.HTTP_VALDATION_ERROR) {
            _that.form.setFields({
              email: {
                value: value,
                errors: [new Error("E-mail est deja utilisé")],
              },
            });
            callback("E-mail est deja utilisé");
          }
        });
    },
    validateProfilePicture(rule, value, callback) {
      if (!this.isAdmin && this.adherant && !this.adherant.profile_picture) {
        callback("Photo de profil obligatoire !");
        return;
      }
      callback();
    },
    disableRecentDates(current) {
      return (
        current && (current > this.getMinAge() || current < this.getMaxAge())
      );
    },
    getMinAge() {
      return moment().subtract(MIN_AGE, "years");
    },
    getMaxAge() {
      return moment().subtract(MAX_AGE, "years");
    },
    validateMatricule(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }
      let params = {
        matricule: value,
      };
      if (this.adherant && this.adherant.id) {
        params.user_id = this.adherant.id;
      }
      let _that = this;
      this.checkMatriculeExistance(params)
        .then(() => {
          callback();
        })
        .catch((re) => {
          if (re.response.status === this.HTTP_VALDATION_ERROR) {
            _that.form.setFields({
              matricule: {
                value: value,
                errors: [new Error("Matricule est deja utilisé")],
              },
            });
            callback("Matricule est deja utilisé");
          }
        });
    },
    ...mapActions({
      update: "updateAdherent",
      updatePassword: "updateAdherentPassword",
      add: "storeAdherent",
      checkMatriculeExistance: "checkMatriculeExistance",
    }),
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.section_text {
  font-size: large;
  font-weight: bolder;
}

.anticon {
  margin-right: 5px;
}

.adherent_form {
  height: auto;
  margin-left: 2%;
}

.ant-form-item-label {
  text-align: end !important;
}
</style>
