<template>
  <div class="bg">
    <a-row type="flex" justify="center">
      <a-col :span="20">
        <a-steps style="color : red;" :current="current">
          <a-step
            style="color : red;"
            v-for="item in steps"
            :key="item.title"
            @click="goTo(item.Index)"
            :title="item.title"
          />
          <a-step
            style="color : red;"
            v-if="!isAdmin"
            key="Confirmation"
            @click="goTo(3)"
            title="Confirmation"
          />
        </a-steps>
        <div class="steps-content">
          <a-skeleton
            :loading="!adherant && editMode"
            :active="true"
            :title="false"
            :paragraph="{ rows: 20 }"
          >
            <PersonalForm
              v-if="current === PERSONAL_FORM"
              ref="personalForm"
              :adherant="adherant"
              :wilayas="wilayas"
              :is-admin="isAdmin"
              :auto="auto"
              :photo-list="photoList"
              :profile-pic-upload-url="profilePicUploadUrl"
              @update_profile_pic="updateProfilePic"
              @clear_profile_pic="handleClearProfilePic"
            ></PersonalForm>
          </a-skeleton>

          <ProfessionalForm
            v-if="current === PROFESSIONAL_FORM"
            ref="professionalForm"
            :adherant="adherant"
            :statuts="statuts"
            :wilayas="wilayas"
            :wilayas-professional="wilayasProfessional"
            :specialites="specialites"
            :communes="communes"
            :diplomes="diplomes"
            :secteurs="secteurs"
            :file-list="fileList"
            :is-admin="isAdmin"
          ></ProfessionalForm>
          <DossierForm
            v-if="current === DOSSIER_FORM"
            ref="dossierForm"
            @refresh_list_dossier="refreshListDossier"
            @delete_dossier="deleteDossier($event)"
            :adherant="adherant"
            :auth-data="auto"
            :is-admin="isAdmin"
            :data-source="listDossier"
          ></DossierForm>
          <EtatActiviteForm
            v-if="current === ETAT_ACTIVITE_FORM"
            ref="etatActiviteForm"
            @add="$emit('addEtatActvite', $event)"
            @update="$emit('updateEtatActvite', $event)"
            @delete="$emit('deleteEtatActivite', $event)"
            @delete_file="$emit('deleteEtatActiviteFile', $event)"
            @refresh_data="$emit('getUser', $event)"
            :user="adherant"
            :exercices="exercices"
            :etat-activites="etatActivites"
            :is-admin="isAdmin"
            :etat-actvite-modal-visible="etatActviteModalVisible"
            :processing="savingData"
            :saving="savingData"
            :key="newCotisationFormComponentKey"
          />
          <ConfirmationForm
            v-if="current === CONFIRMATION_FORM && !isAdmin"
            @confirmation_changed="handleConfirmationChange"
            :is-checked="acceptTermsAndConditions"
            :reglement-interieur="reglementInterieur"
          ></ConfirmationForm>
        </div>
        <div class="steps-action">
          <a-spin :spinning="!adherant && editMode">
            <a-button
              v-if="current > PERSONAL_FORM"
              class="precedent"
              @click="prev"
              >Précédent
            </a-button>
            <a-button
              v-if="
                (current < steps.length && !isAdmin) ||
                  (current < ETAT_ACTIVITE_FORM && isAdmin)
              "
              type="primary"
              :loading="savingData"
              @click="next(steps[current].ref)"
              >Suivant
            </a-button>
            <span
              v-if="
                (current === steps.length && !isAdmin) ||
                  (current === steps.length - 1 && isAdmin)
              "
            >
              <a-button
                type="primary"
                @click="handleConfirmer(isAdmin ? steps[current].ref : '')"
                :loading="processing"
                >Confirmer</a-button
              >
            </span>
          </a-spin>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import PersonalForm from "./PersonalForm";
import ConfirmationForm from "./ConfirmationForm";
import DossierForm from "./DossierForm";
import ProfessionalForm from "./ProfessionalForm";
import EtatActiviteForm from "./EtatActiviteForm";
import constants from "@/const/const";
import _forEach from "lodash-es/forEach";

const steps = [
  {
    Index: 0,
    title: "Infos. Personnelles",
    content: `Personal Content `,
    ref: `personalForm`,
  },
  {
    Index: 1,
    title: "Infos. Professionnelles",
    content: "Second-content",
    ref: "professionalForm",
  },
  {
    Index: 2,
    title: "Dossier",
    content: "Third-content",
    ref: "dossierForm",
  },
  {
    Index: 3,
    title: "Etat d'activite",
    content: "Fourth-content",
    ref: "etatActviteForm",
  },
];
export default {
  name: "AdherentProfilIndex",
  components: {
    PersonalForm,
    ConfirmationForm,
    DossierForm,
    ProfessionalForm,
    EtatActiviteForm,
  },
  data() {
    return {
      PERSONAL_FORM: 0,
      PROFESSIONAL_FORM: 1,
      DOSSIER_FORM: 2,
      ETAT_ACTIVITE_FORM: 3,
      CONFIRMATION_FORM: 4,
      steps,
      loading: false,
      confirmed: false,
    };
  },
  props: [
    "adherant",
    "wilayas",
    "wilayasProfessional",
    "secteurs",
    "specialites",
    "etatActivites",
    "communes",
    "diplomes",
    "exercices",
    "statuts",
    "listDossier",
    "fileList",
    "photoList",
    "profilePicUploadUrl",
    "acceptTermsAndConditions",
    "reglementInterieur",
    "isAdmin",
    "etatActviteModalVisible",
    "auto",
    "current",
    "processing",
    "savingData",
    "processingEtatActivite",
    "savingEtatActivite",
    "newCotisationFormComponentKey",
  ],

  methods: {
    goTo(stepToGo) {
      // If not Allow only going back in  steps to gurantie info integrity
      // if (this.current > stepToGo) {
      //   this.current = stepToGo;
      // }
    },
    updateProfilePic(fileList) {
      this.$emit("update_profile_pic");
    },
    refreshListDossier() {
      this.$emit("refresh_list_dossier");
    },
    deleteDossier(value) {
      this.$emit("delete_dossier", value);
    },
    handleIsDossierCompleteChange($event) {
      this.$emit("is_dossier_complete_change", $event.target.checked);
    },
    handleConfirmer(ref) {
      let that = this;
      if (!this.acceptTermsAndConditions) {
        this.$_throwAnError(`${constants.ACCEPT_TERMS}`);
        return;
      }
      if (this.current === this.ETAT_ACTIVITE_FORM && this.isAdmin) {
        this.$emit("updated_soumission_state", that.acceptTermsAndConditions);
        return;
      }
      this.$emit("updated_soumission_state", that.acceptTermsAndConditions);
    },
    handleClearProfilePic() {
      this.$emit("clear_profile_pic");
    },
    isDossierRequisValide: function(self) {
      let isValide = true;
      _forEach(this.adherant.list_dossier, function(dossier, key) {
        if (!dossier.file_name && dossier.is_obligatoire) {
          self.$_throwAnError(`Il faut Joindre ${dossier.nom_fr}`);
          isValide = false;
        }
      });
      return isValide;
    },
    handleConfirmationChange(e) {
      this.acceptTermsAndConditions = e;
    },
    next(ref) {
      // let canPass = true;
      if (this.current === this.DOSSIER_FORM) {
        let self = this;
        if (this.isAdmin || this.isDossierRequisValide(self)) {
          this.$emit("next");
        }
      }
      if (this.current === this.ETAT_ACTIVITE_FORM) {
        this.$emit("next");
      }
      if (
        this.current === this.PROFESSIONAL_FORM ||
        this.current === this.PERSONAL_FORM
      ) {
        // console.warn(`form is ${ref}`)
        const form = this.$refs[ref].form;
        let fields = form.getFieldsValue();
        // let fieldsTouched = form.isFieldsTouched();

        form.validateFieldsAndScroll((err, values) => {
          if (!err) {
              let user = {};
              for (const key in fields) {
                if (fields.hasOwnProperty(key)) {
                  const element = fields[key];
                  user[key] = element;
                }
              }
              this.formatFields(user, fields, [
                "dtn",
                "dt_diplome",
                "dt_autorisation",
                "dt_retraite",
                "date_changement",
                "dt_depot_dossier", 
                "dt_comission",
                "dt_bac"
              ]);
              // canPass = false;
              this.$emit("save", user);
          }
        });
      }
    },
    prev() {
      this.$emit("previous");
    },
  },
};
</script>
<style scoped>
.bg {
  background: url("../../../assets/bg.jpg");
  width: 100%;
  height: 100%;
}

.precedent{
 margin-right: 8px ;
 margin-bottom: 3% ;

}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.section_text {
  text-align: left;
}

.ant-steps-item-icon:hover {
  cursor: pointer;
}

.section_text {
  font-size: large;
  font-weight: bolder;
  margin-left: 10px;
  margin-right: 10px;
}

.anticon {
  margin-right: 10px;
}

.adherent_form {
  height: auto;
  margin-left: 2%;
}

.docs_print {
  font-size: 1.1rem;
  font-weight: 500;
  margin-left: 20%;
}
</style>
