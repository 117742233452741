<template>
  <a-form :layout="formLayout" class="adherent_form" :form="form">
    <a-row type="flex" justify="space-between">
      <a-col :xs="24" :md="12" v-if="!userHasEtatActivites">
        <a-form-item>
          <p class="section_text"><a-icon type="user" />Activité (Courante)</p>
        </a-form-item>
        <span>
          <a-form-item v-bind="formItemLayout" label="Date  activité">
            <a-date-picker
              :format="dateFormat"
              :disabledDate="disabledFutureDates"
              :showToday="false"
              style="width:100%"
              v-decorator="[
                'date_changement',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'La date d\'activité est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Secteur d'activité">
            <a-select
              v-decorator="[
                'secteur_activite',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Le secteur d\'activité  est obligatoire!',
                    },
                  ],
                },
              ]"
              placeholder="Choisir un secteur"
            >
              <a-select-option v-for="secteur in secteurs" :key="secteur.id">
                {{ secteur.nom_fr }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Statut professionnel">
            <a-select
              @change="handleStatuProfessionallChange"
              v-decorator="[
                'statut_professionnel',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Le statut professionnel est obligatoire!',
                    },
                    ,
                    {
                      validator: statutDefaultValueIsnotPermited,
                    },
                  ],
                },
              ]"
              placeholder="Choisir un statut"
            >
              <a-select-option :key="0">Choisir un statut</a-select-option>

              <a-select-option v-for="statut in statuts" :key="statut.id">{{
                statut.nom_fr
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Motif">
            <textarea
              autosize
              style="width:100%"
              v-decorator="[
                'motif',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Motif est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </span>
        <a-form-item
          v-bind="formItemLayout"
          label="Date  retraite"
          v-show="showDateRetaite"
        >
          <a-date-picker
            :format="dateFormat"
            :disabledDate="disabledFutureDates"
            :showToday="false"
            style="width:100%"
            v-decorator="['dt_retraite']"
          />
        </a-form-item>

        <br />
        <br />
        <br />
        <br />
      </a-col>
      <a-col :xs="24" :md="12">
        <a-form-item>
          <p class="section_text"><a-icon type="audit" />Diplôme</p>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Date d'installation">
          <a-date-picker
            :format="dateFormat"
            :disabledDate="disabledFutureDates"
            :showToday="false"
            style="width:100%"
            v-decorator="[
              'dt_autorisation',
              {
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'La date d\'installation est obligatoire!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <span slot="label">
            N° diplôme/
            <br />attestation provisoire
            <a-tooltip title="N° diplôme/attestation provisoire?"></a-tooltip>
          </span>
          <a-input
            v-decorator="[
              'num_diplome',
              {
                rules: [
                  {
                    required: !isAdmin,
                    message:
                      'Le N° diplôme/attestation provisoire est obligatoire!',
                    whitespace: true,
                  },
                  {
                    pattern: /.{3}/,
                    message: 'Il faut au moins 3 caractères !',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Diplôme">
          <a-select
            v-decorator="[
              'diplome_id',
              {
                rules: [
                  { required: true, message: 'Le diplôme est obligatoire!' },
                  { validator: checkEmptyValue },
                ],
              },
            ]"
            placeholder="Choisir un diplôme"
          >
            <a-select-option :key="0">Choisir un diplôme</a-select-option>
            <a-select-option v-for="diplome in diplomes" :key="diplome.id">{{
              diplome.nom_fr
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Spécialité">
          <a-select
            :defaultValue="0"
            :allowClear="true"
            v-decorator="[
              'specialite_id',
              {
                rules: [
                  { required: true, message: 'La spécialité est obligatoire!' },
                  { validator: checkEmptyValue },
                ],
              },
            ]"
            placeholder="Choisir une spécialité"
          >
            <a-select-option :key="0">Choisir une spécialité</a-select-option>
            <a-select-option
              v-for="specialite in specialites"
              :key="specialite.id"
              >{{ specialite.nom_fr }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <span slot="label">
            Université
            <a-tooltip title="Université ?"></a-tooltip>
          </span>
          <a-input
            v-decorator="[
              'universite',
              {
                rules: [
                  {
                    required: true,
                    message: 'L\'université est obligatoire!',
                    whitespace: true,
                  },
                  {
                    pattern: /.{3}/,
                    message: 'Il faut au moins 3 caractères !',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <span slot="label">
            Date diplôme/
            <br />attestation provisoire
            <a-tooltip
              title="Date de diplôme/attestation provisoire"
            ></a-tooltip>
          </span>
          <a-month-picker
            format="MM-YYYY"
            :disabledDate="disabledFutureDates"
            :showToday="false"
            style="width:100%"
            v-decorator="[
              'dt_diplome',
              {
                rules: [
                  {
                    type: 'object',
                    required: !isAdmin,
                    message:
                      'Date de diplôme/attestation provisoire est obligatoire!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Série du bac ">
          <a-select
            v-decorator="[
              'bac_id',
              {
                rules: [
                  { required: false, message: 'Le diplôme est obligatoire!' },
                ],
              },
            ]"
            placeholder="Choisir un bac"
          >
            <a-select-option :value="0">Choisir</a-select-option>
            <a-select-option :value="1">Mathématique</a-select-option>
            <a-select-option :value="2">Technique mathématique</a-select-option>
            <a-select-option :value="3">Sciences de la nature et de la vie</a-select-option>
          </a-select>
        </a-form-item>
      
        <a-form-item v-bind="formItemLayout">
          <span slot="label">
            Obtenue le 
          </span>
          <a-month-picker
            format="MM-YYYY"
            :disabledDate="disabledFutureDates"
            :showToday="false"
            style="width:100%"
            v-decorator="[
              'dt_bac'
            ]"
          />
        </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Lieu d'obtention">
          <a-select
                  :filter-option="filterWilayaProfessionel"
                  option-filter-prop="children"
                  show-search
                  v-decorator="[
              'wilaya_bac_id',
              {
                rules: [
                  {
                    required: false,
                    message: 'La wilaya d\'activité est obligatoire !',
                  }
                ],
              },
            ]"
            placeholder="Choisir  une wilaya"
          >
            <a-select-option :key="0">Choisir une wilaya</a-select-option>
            <a-select-option
              v-for="wilaya in wilayas"
              :key="wilaya.id"
              >{{ wilaya.nom_fr }}</a-select-option
            >
          </a-select>
        </a-form-item>
        </a-col>
      <a-col :xs="24" :md="12">
        <a-form-item>
          <p class="section_text">
            <a-icon type="home" />Adresse professionnelle
          </p>
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <span slot="label">
            Adresse
            <a-tooltip title="Ou travaillez vous ?"></a-tooltip>
          </span>
          <a-textarea
            v-decorator="[
              'adresse_professionnelle_fr',
              {
                rules: [
                  { required: !isAdmin, message: 'Adresse!', whitespace: true },
                  {
                    pattern: /.{3}/,
                    message: 'Il faut au moins 3 caractères !',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <span slot="label">
            Adresse (Ar)
            <a-tooltip title="Ou travaillez vous ?"></a-tooltip>
          </span>
          <a-textarea
            v-decorator="[
              'adresse_professionnelle_ar',
              {
                rules: [
                  { required: false, message: 'Adresse!', whitespace: true },
                  {
                    pattern: /[\u0600-\u06FF]/,
                    message: 'Le format  l\'adresse n\'est pas valide ',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Wilaya">
          <a-select
                  :filter-option="filterWilayaProfessionel"
                  option-filter-prop="children"
                  show-search
                  @change="handleWilayasChange"
                  v-decorator="[
              'wilaya_professionnelle_id',
              {
                rules: [
                  {
                    required: true,
                    message: 'La wilaya d\'activité est obligatoire !',
                  },
                  { validator: checkEmptyValue },
                ],
              },
            ]"
            placeholder="Choisir  une wilaya"
          >
            <a-select-option :key="0">Choisir une wilaya</a-select-option>
            <a-select-option
              v-for="wilaya in wilayasProfessional"
              :key="wilaya.id"
              >{{ wilaya.nom_fr }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Commune">
          <a-select
                  showSearch
                  optionFilterProp="children"
                  :filterOption="filterCommuneProfessionel"
                  v-decorator="[
              'commune_professionnelle_id',
              {
                rules: [
                  { required: false, message: 'Commune est obligatoire!' },
                ],
              },
            ]"
                  placeholder="Choisir une commune"
          >
            <a-select-option :key="0">Choisir une commune</a-select-option>
            <a-select-option
              v-for="commune in local_communes"
              :key="commune.id"
              >{{ commune.nom_fr }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="12" >
        <a-form-item>
          <p class="section_text">
            <a-icon type="contacts" />Contact professionnel
          </p>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="Fixe.professionnel">
          <a-input
            v-decorator="[
              'tel_professionel_fixe',
              {
                rules: [
                  {
                    required: false,
                    message: 'Le numéro de tél est obligatoire!',
                    whitespace: true,
                  },
                  {
                    pattern: /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/,
                    message: 'Le format de Tél n\'est pas valide ',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Mobile.professionnel">
          <a-input
            v-decorator="[
              'tel_professionel_mobile',
              {
                rules: [
                  {
                    required: !isAdmin,
                    message: 'Le numéro de tél est obligatoire!',
                    whitespace: true,
                  },
                  {
                    pattern: /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/,
                    message: 'Le format de Tél n\'est pas valide ',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Fax.professionnel">
          <a-input
            v-decorator="[
              'tel_professionel_fax',
              {
                rules: [
                  {
                    required: false,
                    message: 'Le numéro de tél est obligatoire!',
                    whitespace: true,
                  },
                  {
                    pattern: /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/,
                    message: 'Le format de Tél n\'est pas valide ',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
      </a-col>
      <br />
    </a-row>
  </a-form>
</template>

<script>
// const ADHERENTS_URL = `/admin/adherents`;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default {
  name: "ProfessionalForm",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    if (this.adherant) {
      this.adherant.dt_diplome = this.adherant.dt_diplome
        ? this.momentDate(this.adherant.dt_diplome)
        : null;

      this.adherant.dt_retraite = this.adherant.dt_retraite
        ? this.momentDate(this.adherant.dt_retraite)
        : "";
      this.adherant.dt_autorisation = this.adherant.dt_autorisation
        ? this.momentDate(this.adherant.dt_autorisation)
        : "";
       this.adherant.dt_bac = this.adherant.dt_bac
        ? this.momentDate(this.adherant.dt_bac)
        : "";
      this.form.setFieldsValue(this.adherant);
      this.selectedStatuProfessional = this.adherant.statut_professionnel;
      this.setLocalCommunes();
    }
  },
  data() {
    return {
      formItemLayout,
      tailFormItemLayout,
      selectedStatuProfessional: null,
      local_communes: this.communes,
    };
  },
  computed: {
    showDateRetaite() {
      return this.isUserStatuProfessRetraiter(this.selectedStatuProfessional);
    },
    userHasEtatActivites() {
      return this.adherant ? !!this.adherant.etat_activites.length : false;
    },
  },
  props: [
    "adherant",
    "wilayas",
    "wilayasProfessional",
    "secteurs",
    "statuts",
    "specialites",
    "communes",
    "diplomes",
    "isAdmin",
  ],

  methods: {
    filterWilayaProfessionel(input, option) {
      return (
              option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    filterCommuneProfessionel(input, option) {
      return (
              option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    setLocalCommunes() {
      this.local_communes = this.communes.filter((item) => {
        return (
                item.wilaya_id ===
                this.get(this.adherant, "wilaya_professionnelle_id")
        );
      });
    },
    statutDefaultValueIsnotPermited(rule, value, callback) {
      const defaultValue = 0;
      let message = "Le statut professionnel est obligatoire!";
      this.checkDefaultValue(defaultValue, callback, this.form.getFieldValue("statut_professionnel"), message);
    },
    handleStatuProfessionallChange(value) {
      this.selectedStatuProfessional = value;
    },
    handleWilayasChange(value) {
      let s = this.communes.filter((item) => {
        return item.wilaya_id === value;
      });
      this.form.setFieldsValue({ communes: 0 });
      this.local_communes = s;
    },
    checkEmptyValue(rule, value, callback) {
      const defaultValue = 0;
      let message = "Il faut choisir une option";
      this.checkDefaultValue(defaultValue, callback, parseInt(value), message);
    },
  },
};
</script>
<style scoped>
.section_text {
  font-size: large;
  font-weight: bolder;
}
.anticon {
  margin-right: 5px;
}
</style>
