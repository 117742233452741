<template>
    <a-modal
            style="bottom: 20px;"
            title="Modifier mot de passe"
            :visible="visible"
            @cancel="handleCancel"

            :destroyOnClose="true"
            :okButtonProps="{ props: {htmlType : 'submit'} }"
    >
        <template slot="footer">
            <a-button key="submit" type="primary" :loading="processing" @click="handleOk">Confirmer</a-button>
        </template>
        <a-form
                :layout="formLayout"
                id="add_attestation_form"
                name="add_attestation_form"
                class="adherent_form"
                :form="form"
        >
            <a-row>
                <a-form-item v-bind="formItemLayout">
                    <a-input-password
                            placeholder="Mot de passe"
                            v-decorator="[
          'password',
          {
               rules: [
                       {required: true, message:'Mot de passe est obligatoire!'},
                       {pattern: /(?=.*\d.*)(?=.*[a-zA-Z].*).{8,20}/,
                        message:' Utilisez au moins 8 caractères avec des lettres, des chiffres pour votre mot de passe !'},
                       {validator: this.validateToNextPassword}
                       ]
          } ]"
                    >
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>

                    </a-input-password>
                </a-form-item>
                <a-form-item v-bind="formItemLayout">
                    <a-input-password

                            @blur="handleConfirmBlur"
                            placeholder="Confirmer mot de passe"
                            v-decorator="[
          'password_confirmation',
          {   rules: [{
            required: true, message: 'Confirmation Mot de passe est obligatoire!',
          }, {
            validator: compareToFirstPassword,
          }],}
        ]"
                    >
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
                    </a-input-password>
                </a-form-item>
            </a-row>
        </a-form>
    </a-modal>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: "NewMail",
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        created() {

        },
        mounted() {
            if (this.replyTo) {
                this.form.setFieldsValue({to: this.replyTo});
            }
        },
        data() {
            return {
                confirmDirty: false,
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 24}
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 24}
                    }
                },
                tailFormItemLayout: {
                    wrapperCol: {
                        xs: {
                            span: 22,
                            offset: 0
                        },
                        sm: {
                            span: 22,
                            offset: 20
                        }
                    }
                }
            };
        },
        props: ["visible", "isAdmin", "processing", "replyTo", "isDestinationsSelectDisabled"],
        computed: {
            ...mapState({
                adherents: state => state.adherents.unfiltered_adherents
            })
        },
        methods: {
            close() {
                this.$emit("close");
            },
            handleCancel() {
                this.$emit("cancel");
            },
            handleOk() {
                this.$emit("create");
            },
            handleConfirmBlur(e) {
                const value = e.target.value;
                this.confirmDirty = this.confirmDirty || !!value;
            },
            compareToFirstPassword(rule, value, callback) {
                const form = this.form;
                if (value && value !== form.getFieldValue("password")) {
                    callback(
                        "Les deux mots de passe ne sont pas identiques !"
                    );
                } else {
                    callback();
                }
            },
            validateToNextPassword(rule, value, callback) {
                const form = this.form;
                if (value && this.confirmDirty) {
                    form.validateFields(["password_confirmation"], {force: true});
                }
                callback();
            },
        }
    };
</script>